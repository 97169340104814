import { castDateTimeFilters, DateTimeFiltersRequest } from '../../utils/requestHelper';
import { done, failed, RemoteData } from '../../utils/remote-data';
import { get, post } from './base';
import { CowsSurvey, Surveys, CiwaSurvey } from '../../interfaces/survey';
import { DateRange } from 'components/common/date-picker/date-range-picker';
import moment from 'moment';

export const getSurveys = async (
  patientId: string,
  dateFilters: DateTimeFiltersRequest,
): Promise<RemoteData<Surveys>> => {
  try {
    const res = await get(`/survey-responses?patientId=${patientId}`, {
      params: {
        ...castDateTimeFilters(dateFilters),
      },
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const submitCowData = async (patientFbUserId: string, cowData: CowsSurvey): Promise<RemoteData<any>> => {
  // Adjust the return type as necessary
  try {
    const res = await post(`/clinician/patient/${patientFbUserId}/cow?patientFbUserId=${patientFbUserId}`, cowData);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const submitCiwaData = async (patientFbUserId: string, ciwaData: CiwaSurvey): Promise<RemoteData<any>> => {
  try {
    const res = await post(`/clinician/patient/${patientFbUserId}/ciwa`, ciwaData);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getSurvey = async (
  patientId: string,
  dateFilters: DateRange
): Promise<RemoteData<any>> => {
  try {
    const res = await get(`clinician/fetch-surveys?patientId=${patientId}&startDateTime=${moment(dateFilters.startDate).format("YYYY-MM-DDThh:mm:ss")}&endDateTime=${moment(dateFilters.endDate).format("YYYY-MM-DDThh:mm:ss")}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
}

export const surveyPDF = async (
  patientId: string,
  surveyId: number
): Promise<RemoteData<Blob>> => {
  try {
    const res = await get(`surveys/pdf?patientId=${patientId}&resultId=${surveyId}`, {
      responseType: 'arraybuffer', // Specify response type as arraybuffer
    });

    const blob = new Blob([res.data], { type: 'application/pdf' });
    return done(blob);
  } catch (err) {
    return failed(err);
  }
}
