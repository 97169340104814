import { getPatientTimezone } from 'contexts/PatientContext';
import { QuestionnaireInstance } from 'graphql/codegen/schemas';
import { Patient } from 'interfaces/patient';
import { Practitioner } from 'interfaces/practitioner';
import React, { FunctionComponent, useState, useMemo, useRef } from 'react';
import { displayDateTimeFormat } from 'utils/data-parser';
import * as html2pdf from 'html2pdf.js';
import { isSurveyUsable } from '../is-survey-useful';
import { getStatusColor, getStatusIcon, getStatusText } from '../survey-status-styling';
import ViewSurveyModalQuestion from './view-survey-modal-question';
import styles from './view-survey-modal.module.scss';
import { ReactComponent as DownloadButton } from '../../../assets/icons/icn_download.svg';
import ViewGADSurveyModalQuestion from './view-gad-survey-modal';
import ViewPhqSurveyModal from './view-phq-survey-modal';
import ViewPennCravingModal from './view-penn-craving-modal';
import ViewAwareSurveyModal from './view-aware-survey-modal';
import { surveyPDF } from 'services/api/survey';
import { RemoteData } from 'utils/remote-data';
import moment from 'moment-timezone';

type Props = {
  closeModal: () => void;
  patient: Patient;
  viewSurvey: QuestionnaireInstance | null;
  practitioner: Practitioner;
};

const ViewSurveyModal: FunctionComponent<Props> = ({ closeModal, patient, viewSurvey, practitioner }) => {
  const timezone = getPatientTimezone();
  const [gettingPdf] = useState(false);
  const componentRef2 = useRef<HTMLElement>(null);

  // const print = async () => {
  //   const height = viewSurvey ? viewSurvey.questions.length * 1000 / 2 : 2000
  //   const size = viewSurvey?.questionnaire?.name === 'AWARENESS' ? 6000 : height;
  //   const name = `${patient?.name?.first}-${patient?.name?.last}-${viewSurvey?.questionnaire?.name}-${viewSurvey?.created.split('T')?.[0]}.pdf`
  //   const opt = {
  //     margin:       1,
  //     filename:     name,
  //     pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
  //     html2canvas:  { scale: 10, height: size },
  //   };
  //   html2pdf()
  //   .set(opt)
  //   .from(componentRef2.current)
  //   .toContainer()
  //   .then(()=>{
  //     const doc = document.querySelector('.html2pdf__container') as HTMLElement;
  //     if (doc) {
  //       const section = doc?.querySelector('#survey-scroll-section') as HTMLElement
  //       if (section?.style) {
  //         section.style.overflow = 'visible';
  //       }
  //     }
  //   })
  //   .save();
  // };

  console.log("View Survey; ", viewSurvey);

  console.log("View patient: ", patient);

  const setPDFName = (viewSurvey) => {
    let filename;

    switch (viewSurvey.questionnaire.clinicName) {
        case 'Tinnitus Functional Index':
            filename = `TFI_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
        case 'Sleep Quality Scale':
            filename = `SQS_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
        case 'Tinnitus Handicap Inventory':
            filename = `THI_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
        case 'Commitment to Sobriety':
            filename = `Commitment_to_Sobriety_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
        case 'Generalized Anxiety Disorder (GAD-7)':
            filename = `GAD7_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
        case 'PENN Alcohol/Drug Craving Survey':
            filename = `PENN_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
        case 'PTSD Questionnaire':
            filename = `PTSD_Relapse_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
        case 'Patient Health Questionnaire (PHQ-9)':
            filename = `PHQ9_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
        case 'Addiction Relapse Prediction Questionnaire':
            filename = `Addiction_Relapse_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
        case 'Advanced Warning Relapse (Aware) Questionnaire':
            filename = `Aware_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
        case 'Stress Questionnaire':
            filename = `Stress_${patient.name.first}_${patient.name.last}_${moment(viewSurvey.created).format("MMDDYYYY")}.pdf`;
            break;
}
 return filename; // Set the filename for the downloaded PDF

  }

  const print = async () => {
      if (viewSurvey) {
      const result = await surveyPDF(patient.id, viewSurvey.id);
      if (result.status === 'Done') {
        const url = window.URL.createObjectURL(result.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = setPDFName(viewSurvey); // Filename for the downloaded PDF
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url); // Clean up URL object
      } else {
        console.error('Failed to download PDF:', result.status);
        // Handle error (e.g., show a message to the user)
      }
    }
  }
  
  const getContent = useMemo(() => {
    if (viewSurvey?.questionnaire?.name === 'GAD7') {
      return <ViewGADSurveyModalQuestion viewSurvey={viewSurvey} patient={patient} practitioner={practitioner} />;
    }
    if (viewSurvey?.questionnaire?.name === 'PHQ_9') {
      return <ViewPhqSurveyModal viewSurvey={viewSurvey} />;
    }

    if (viewSurvey?.questionnaire?.name === 'PENN Craving Questionnaire') {
      return <ViewPennCravingModal viewSurvey={viewSurvey} />;
    }

    if (viewSurvey?.questionnaire?.name === 'AWARENESS') {
      return <ViewAwareSurveyModal viewSurvey={viewSurvey} />;
    }

    return (
      <section className={styles.questions}>
        {viewSurvey?.questions?.map((q, idx) => (
          <ViewSurveyModalQuestion key={q.id} question={q} index={idx + 1} />
        ))}
      </section>
    );
  }, [viewSurvey?.id, patient, practitioner]);

  if (!viewSurvey) return null;

  return (
    <section className={`${styles.bacTestRequestModal} survey`} ref={componentRef2}>
      <div className={styles.modalHeader}>
        <h4>{viewSurvey.questionnaire.clinicName}</h4>
        <div className={styles.patientInfoRow}>
          <h5>
            Client name: {patient?.name?.first} {patient?.name?.last}
          </h5>
          <h5>Patient ID: {patient?.id}</h5>
        </div>
      </div>
      <section className={styles.modalSubHeader}>
        <div>Survey group: {viewSurvey.questionnaire.groupName}</div>
        <div>Date: {displayDateTimeFormat(viewSurvey.created, timezone)}</div>
        <div className={styles.didSurvey}>
          <div>Did survey:</div>
          <img src={getStatusIcon(viewSurvey)} alt={`${getStatusText(viewSurvey)} icon`} />
          <div style={{ color: getStatusColor(viewSurvey) }}>{getStatusText(viewSurvey)}</div>
        </div>
        <div>Survey Score: {isSurveyUsable(viewSurvey) ? viewSurvey.value : '-'}</div>
        <DownloadButton style={{ cursor: 'pointer' }} onClick={print} />
      </section>
      <section style={{ margin: 30 }} className={gettingPdf ? styles.none : styles.scroll} id='survey-scroll-section'>
        {getContent}
      </section>
    </section>
  );
};

export default ViewSurveyModal;
